// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Contact.css */
.ContactStyleContainer {
    min-height: 100vh;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
}

.ContactStyleContainer div {
    margin-bottom: 20px;
}

.ContactStyleContainer div:last-child {
    margin-bottom: 0;
}

.ContactStyleContainer p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.ContactStyleContainer div > div {
    margin-bottom: 10px;
}

.ContactStyleContainer div > div p {
    font-weight: bold;
    color: #555;
}

.ContactStyleContainer div > div p:first-child {
    margin-bottom: 5px;
    font-weight: normal;
}

.ContactStyleContainer h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #444;
}

@media (max-width: 768px) {
    .ContactStyleContainer {
        padding: 15px;
    }

    .ContactStyleContainer div {
        margin-bottom: 15px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Contact/Contact.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":["/* Contact.css */\n.ContactStyleContainer {\n    min-height: 100vh;\n    max-width: 600px;\n    margin: 50px auto;\n    padding: 20px;\n    background: #f9f9f9;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n    font-family: Arial, sans-serif;\n}\n\n.ContactStyleContainer div {\n    margin-bottom: 20px;\n}\n\n.ContactStyleContainer div:last-child {\n    margin-bottom: 0;\n}\n\n.ContactStyleContainer p {\n    margin: 0;\n    font-size: 16px;\n    color: #333;\n}\n\n.ContactStyleContainer div > div {\n    margin-bottom: 10px;\n}\n\n.ContactStyleContainer div > div p {\n    font-weight: bold;\n    color: #555;\n}\n\n.ContactStyleContainer div > div p:first-child {\n    margin-bottom: 5px;\n    font-weight: normal;\n}\n\n.ContactStyleContainer h2 {\n    font-size: 24px;\n    margin-bottom: 20px;\n    color: #444;\n}\n\n@media (max-width: 768px) {\n    .ContactStyleContainer {\n        padding: 15px;\n    }\n\n    .ContactStyleContainer div {\n        margin-bottom: 15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
