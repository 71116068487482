// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-home {
    display: flex;
    min-height: 100vh;
}

.admin-sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: #333;
    padding: 20px;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.2);
}

.admin-sidebar-item {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 15px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: background-color 0.3s, transform 0.3s;
}

.admin-sidebar-item.active, .admin-sidebar-item:hover {
    background-color: #555;
    transform: translateX(10px);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.admin-content {
    flex-grow: 1;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    transition: all 0.3s;
}

.admin-content:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/AdminHome/AdminHome.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,yCAAyC;AAC7C;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,iDAAiD;AACrD;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,0CAA0C;AAC9C;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;IACvC,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".admin-home {\n    display: flex;\n    min-height: 100vh;\n}\n\n.admin-sidebar {\n    display: flex;\n    flex-direction: column;\n    width: 200px;\n    background-color: #333;\n    padding: 20px;\n    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.2);\n}\n\n.admin-sidebar-item {\n    background: none;\n    border: none;\n    color: #fff;\n    font-size: 16px;\n    cursor: pointer;\n    padding: 15px 20px;\n    border-radius: 5px;\n    margin-bottom: 10px;\n    transition: background-color 0.3s, transform 0.3s;\n}\n\n.admin-sidebar-item.active, .admin-sidebar-item:hover {\n    background-color: #555;\n    transform: translateX(10px);\n    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);\n}\n\n.admin-content {\n    flex-grow: 1;\n    background-color: #f9f9f9;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    margin-left: 20px;\n    transition: all 0.3s;\n}\n\n.admin-content:hover {\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
