// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Apply CSS reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure full viewport height */
html, body, #root {
  height: 100%;
}

/* Ensure no overflow and full width */
body {
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Adjust font-family as needed */
  background-color: #1a1a1a; /* Set background color for the entire page */
  color: #ffffff; /* Set default text color */
}
*::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

* {
  -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA,gCAAgC;AAChC;EACE,YAAY;AACd;;AAEA,sCAAsC;AACtC;EACE,kBAAkB,EAAE,8BAA8B;EAClD,4DAA4D,EAAE,iCAAiC;EAC/F,yBAAyB,EAAE,6CAA6C;EACxE,cAAc,EAAE,2BAA2B;AAC7C;AACA;EACE,aAAa,EAAE,iDAAiD;AAClE;;AAEA;EACE,wBAAwB,GAAG,kDAAkD;EAC7E,qBAAqB,GAAG,+BAA+B;AACzD","sourcesContent":["/* Apply CSS reset */\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n/* Ensure full viewport height */\nhtml, body, #root {\n  height: 100%;\n}\n\n/* Ensure no overflow and full width */\nbody {\n  overflow-x: hidden; /* Prevent horizontal scroll */\n  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Adjust font-family as needed */\n  background-color: #1a1a1a; /* Set background color for the entire page */\n  color: #ffffff; /* Set default text color */\n}\n*::-webkit-scrollbar {\n  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */\n}\n\n* {\n  -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */\n  scrollbar-width: none;  /* Hide scrollbar for Firefox */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
